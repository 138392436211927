import React, { useState, useEffect } from "react";
import { TradingCalculatorQuery } from "../../prismic/staticQueries/markets/index.js"
import Layout from "../../layout";
import { Subscription, BreadCrumbBanner } from "../../components/common";
import "react-multi-carousel/lib/styles.css";
import { connect, useSelector } from "react-redux";
import { Container, Row, Form, Col, Button } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import Carousel from "react-multi-carousel";
import CountUp from "react-countup";
import banner_image from "../../images/siteImages/forex/trading-calculator.png";
import markets_side_image from "../../images/siteImages/forex/calc.png";
import pipcal from "../../images/siteImages/forex/pipcalculator11.jpg";
import tradingvolume from "../../images/siteImages/forex/MarketIcons/trading_volume.svg";
import currencypair from "../../images/siteImages/forex/MarketIcons/currency_pairs.svg";
import forexmarkethours from "../../images/siteImages/forex/MarketIcons/forex_market_hours.svg";
import tradingflexibility from "../../images/siteImages/forex/MarketIcons/trading_flexibility.svg";


const TradingCalculator = () => {
  const language = useSelector((state) => state.language);
  const TradingCalculatorData = TradingCalculatorQuery(language);
  console.log("language", TradingCalculatorData);
  const {
    markets_heading,
    markets_content,
    // markets_side_image,
    all_markets_box,
  } = TradingCalculatorData;

  const all_markets_icon = [
    tradingvolume,
    currencypair,
    forexmarkethours,
    tradingflexibility
  ];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // State for user inputs
  const [accountType, setAccountType] = useState('');
  const [accountCurrency, setAccountCurrency] = useState('USD'); // Default USD
  const [instrument, setInstrument] = useState('XAUUSDm'); // Default instrument
  const [lot, setLot] = useState(0.2); // Default 0.2 lot
  const [leverage, setLeverage] = useState(200); // Default leverage

  // State for calculated values
  const [pipValue, setPipValue] = useState(null);
  const [margin, setMargin] = useState(null);
  const [spread, setSpread] = useState(null);
  const [commission, setCommission] = useState(null);
  const [swapShort, setSwapShort] = useState(null);
  const [swapLong, setSwapLong] = useState(null);

  // Leverage values
  const leverageOptions = [
    "1:2000", "1:1888", "1:1000", "1:888", "1:800",
    "1:600", "1:500", "1:400", "1:200", "1:100",
    "1:88", "1:50", "1:20", "1:10", "1:2", "UNLIMITED"
  ];

  // Instrument options
  const instrumentOptions = [
    "XAUUSDm", "XAGUSDm", "EURUSD", "GBPUSD", "USDJPY"
  ];

  // Placeholder values for spread and commission rates (example data)
  const instrumentData = {
    "XAUUSDm": { spread: 0.3, commission: 2.0, swapLong: 0.8, swapShort: -0.7 },
    "XAGUSDm": { spread: 0.5, commission: 1.5, swapLong: 0.5, swapShort: -0.4 },
    "EURUSD": { spread: 0.1, commission: 1.0, swapLong: 0.6, swapShort: -0.5 }
  };

  const calculateValues = () => {
    const leverageValue = typeof leverage === 'string' ? parseInt(leverage.split(":")[1]) : leverage;

    const instrumentDataValues = instrumentData[instrument];
    const price = 1900; // Example price of the instrument, replace this with dynamic data if needed

    // Margin Calculation: (Lot Size * Price) / Leverage
    const calculatedMargin = (lot * price) / leverageValue;

    // Pip Value Calculation: (Lot Size * Contract Size) / Price
    const contractSize = 100; // Example value, change as needed
    const calculatedPipValue = (lot * contractSize) / price;

    // Get other values from instrument data
    const calculatedSpread = instrumentDataValues.spread;
    const calculatedCommission = instrumentDataValues.commission * lot;
    const calculatedSwapShort = instrumentDataValues.swapShort;
    const calculatedSwapLong = instrumentDataValues.swapLong;

    // Set calculated values
    setMargin(calculatedMargin);
    setPipValue(calculatedPipValue);
    setSpread(calculatedSpread);
    setCommission(calculatedCommission);
    setSwapShort(calculatedSwapShort);
    setSwapLong(calculatedSwapLong);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    calculateValues();
  };

  // Load Finlogix widget script dynamically
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://widget.finlogix.com/Widget.js";
    script.async = true;
    script.onload = () => {
      if (window.Widget) {
        window.Widget.init({
          widgetId: "7309e416-66fd-4479-90e6-98c7e6c530b6",
          type: "MarginCalculator",
          language: "en",
          isAdaptive: true,
          withBorderBox: true,
        });
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Clean up the script on component unmount
    };
  }, []);


  return (
    <Layout>
      <BreadCrumbBanner banner_image={banner_image} />
      <section className="sectionbg p-top-10 p-bottom-80 faqs">
        <Container className="">
          <Row className="py-5">
            <Col lg={6}>
              <h2 className="pt-5 my-3 text-bold-md text-black">{markets_heading.text}</h2>
              <p className="text-gray my-3">
                {markets_content.text}</p>
            </Col>
            <Col lg={1}></Col>
            <Col lg={5} className="py-5">
              <img className="dubai-img" alt="ddd" src={markets_side_image} />
            </Col>
          </Row>
          <Row className="pb-5">
            <Col lg={12}>
              <h2 className="my-3 text-bold-md text-black text-center">How to use the Rox Capitals trading calculator</h2>
            </Col>
            <Col lg={5} className="py-5">
              <img className="dubai-img" alt="ddd" src={pipcal} />
            </Col>
            <Col lg={1}></Col>
            <Col lg={6}>
              <div class="timeline">
                <div class="timeline__event animated fadeInUp">
                  <div class="timeline__event__date">
                    1
                  </div>
                  <div class="timeline__event__content">
                    <div class="timeline__event__title">
                      Step
                    </div>
                    <div class="timeline__event__description">
                      <p>Choose your Rox Capitals account type and specify your account's leverage and currency.</p>
                    </div>
                  </div>
                </div>
                <div class="timeline__event animated fadeInUp">
                  <div class="timeline__event__date">
                    2
                  </div>
                  <div class="timeline__event__content">
                    <div class="timeline__event__title">
                      Step
                    </div>
                    <div class="timeline__event__description">
                      <p>Select your desired trading instrument from the available list.</p>
                    </div>
                  </div>
                </div>
                <div class="timeline__event animated fadeInUp">
                  <div class="timeline__event__date">
                    3
                  </div>
                  <div class="timeline__event__content">
                    <div class="timeline__event__title">
                      Step
                    </div>
                    <div class="timeline__event__description">
                      <p>Determine your trade's lot size and proceed to calculate by clicking the 'Calculate' button.</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="finlogix-container"></div>
            </Col>
          </Row>
          <Row className="py-1 d-none">
            <Col lg={6}>
              <h5>Your Orders</h5>
              {/* Form for inputs */}
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="accountType" className="mb-4">
                  <Form.Label>Account Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={accountType}
                    onChange={(e) => setAccountType(e.target.value)}
                  >
                    <option value="mt5_mini_real_vc">MT5 Mini Real VC</option>
                    <option value="mt5_standard">MT5 Standard</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="accountCurrency" className="mb-4">
                  <Form.Label>Account Currency</Form.Label>
                  <Form.Control
                    as="select"
                    value={accountCurrency}
                    onChange={(e) => setAccountCurrency(e.target.value)}
                  >
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="instrument" className="mb-4">
                  <Form.Label>Instrument</Form.Label>
                  <Form.Control
                    as="select"
                    value={instrument}
                    onChange={(e) => setInstrument(e.target.value)}
                  >
                    {instrumentOptions.map((inst, index) => (
                      <option key={index} value={inst}>
                        {inst}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="lot" className="mb-4">
                  <Form.Label>Lot</Form.Label>
                  <Form.Control
                    type="number"
                    value={lot}
                    onChange={(e) => setLot(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="leverage" className="mb-5">
                  <Form.Label>Leverage</Form.Label>
                  <Form.Control
                    as="select"
                    value={leverage}
                    onChange={(e) => setLeverage(e.target.value)}
                  >
                    {leverageOptions.map((lev, index) => (
                      <option key={index} value={lev}>
                        {lev}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Button type="submit">Calculate</Button>
              </Form>
            </Col>
            <Col lg={1}></Col>
            <Col lg={5}>
              <h5> Results</h5>
              {/* Display Calculated Values */}
              {pipValue !== null && (
                <div className="mt-5">
                  <div className="d-flex gap-3 align-items-center mb-4"><h5 className="mb-0">Pip Value :</h5><b> {pipValue}</b></div>
                  <div className="d-flex gap-3 align-items-center mb-4"><h5 className="mb-0">Margin :</h5><b> {margin}</b></div>
                  <div className="d-flex gap-3 align-items-center mb-4"><h5 className="mb-0">Spread :</h5><b> {spread}</b></div>
                  <div className="d-flex gap-3 align-items-center mb-4"><h5 className="mb-0">Commission :</h5><b> {commission}</b></div>
                  <div className="d-flex gap-3 align-items-center mb-4"><h5 className="mb-0">Swap Short :</h5><b> {swapShort}</b></div>
                  <div className="d-flex gap-3 align-items-center mb-4"><h5 className="mb-0">Swap Long :</h5><b> {swapLong}</b></div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>

    </Layout>
  );
};
const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(TradingCalculator);